.CalendarDay__selected_span {
    background: #1278BC;
    color: white;
  }
  
  .CalendarDay__selected {
    background: #1278BC;
    color: white;
  }
  
  .CalendarDay__selected:hover {
    background: #838383;
    color: white;
  }
  
  .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
    background: #838383;
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: #18A0FB;
    color: #fff;
  }

  .DateRangePickerInput, .DateInput, .DateInput_input__small {
    background-color: transparent;
  }

  .DateRangePickerInput__withBorder {
    border-radius: 4px;
  }

  .DateRangePickerInput_calendarIcon {
    padding: 5px;
    padding-bottom: 3px;
  }

  .DateRangePickerInput_clearDates__small {
    padding: 4px 7px;
  }
